import { IEnvironment } from './interfaces';

export const environment: IEnvironment = {
    production: true,
    baseUrl: '/3.0/',
    baseUrl_4_0: '/4.0/',
    crmBaseUrl: '/3.1/',
    threeDotOneBaseUrl: '/3.1/',
    resourcesUrlPrefix: '',
    clientId: 'AZAVISTA-3.0',

    // Gets overridden by the build system - DO NOT TOUCH
    sentryRelease: 'staging.2025-01-15-16-59-50'
};
